import React, { Fragment, useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Button from "@mui/material/Button";
//import { DataGrid, RowsProp, ColDef } from "@material-ui/data-grid";
import Spinner from "../Spinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import classNames from "classnames";

export default function Buscar(props) {
  const opcionesBusqueda = [
    { title: "Apellido y nombre" },
    { title: "Domicilio" },
    { title: "Documento" },
    { title: "Teléfono" },
    { title: "Número de socio" },
    { title: "Plan" },
    { title: "Localidad" },
    { title: "Provincia" },
  ];
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [id, setId] = useState(null);

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { tope, adicionales, obtenerTope, obtenerAdicionales } =
    configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    numeroTelefono,
    valBusqueda,
    rows,
    afiliado,
    titular,
    valorizacion,
    selectedRow,
    cantidadServicios,
    guardarValBusqueda,
    capturarTelefonoPaciente,
    obtenerDatosPacientes,
    guardarAfiliado,
    buscarTitular,
    buscarValorizacion,
    setSelectedRow,
    obtenerCantidadServiciosTitular,
  } = serviciosContext;

  const [labelTextBuscar, guardarLabelTextBuscar] = useState("");
  const [valOpcionBusqueda, guardarValOpcionBusqueda] = useState(null);

  const onSelectAutocomplete = (e, value) => {
    if (value) {
      guardarLabelTextBuscar(value.title);
      let item = e.target.id.substring(26, 27);
      guardarValOpcionBusqueda(opcionesBusqueda[item]);
      guardarValBusqueda("");
      let campo = document.getElementById("buscar");
      campo.focus();
    }
  };

  const onClickCaptura = () => {
    guardarValOpcionBusqueda(opcionesBusqueda[3]); // Teléfono
    capturarTelefonoPaciente({ id: 308 });
    guardarLabelTextBuscar("Teléfono");
    let campo = document.getElementById("buscar");
    campo.focus();
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre_razonsocial}
      </Fragment>
    );
  };

  const domicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const telefonoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Teléfono</span>
        {rowData.telefono1}
      </Fragment>
    );
  };

  const documentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento/C.U.I.T.</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const planGrupoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Plan/Grupo</span>
        {rowData.planGrupo}
      </Fragment>
    );
  };

  const habilitadoTemplate = (rowData) => {
    const colorClassName = classNames({
      habilitado: rowData.habilitado && !rowData.suspendido,
      inhabilitado: !rowData.habilitado,
      suspendido: rowData.suspendido && rowData.habilitado,
    });

    return (
      <Fragment>
        <span className="p-column-title">Estado</span>
        <div className={colorClassName}>
          {rowData.habilitado  && !rowData.suspendido 
            ? "Habilitado"
            : rowData.habilitado  && rowData.suspendido 
            ? "Suspendido"
            : "Inhabilitado"}
        </div>
      </Fragment>
    );
  };

  //console.log(valorizacion)

  return (
    <Fragment>
      <Grid container spacing={2} style={{ marginTop: "0vw" }}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            id="combo-box-busqueda"
            value={valOpcionBusqueda}
            options={opcionesBusqueda}
            getOptionLabel={(option) => option.title}
            noOptionsText={"No hay opciones"}
            //style={{ width: 300 }}
            size="small"
            getOptionSelected={(option, value) =>
              typeof value !== "undefined" ? option.title === value.title : null
            }
            onChange={(event, newValue) => {
              onSelectAutocomplete(event, newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Opciones de búsqueda"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />{" "}
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={onClickCaptura}
          >
            Capturar teléfono
          </Button>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="buscar"
            name="buscar"
            value={valBusqueda}
            label={
              labelTextBuscar === "" ? "texto de búsqueda" : labelTextBuscar
            }
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            margin="none"
            variant="standard"
            freeSolo="false"
            autoComplete="family-name"
            onChange={(e) => guardarValBusqueda(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                // Do code here
                ev.preventDefault();
                if (labelTextBuscar === "") {
                  mensajeAlerta(
                    "Toma de Servicio",
                    "Debe seleccionar una opcion de busqueda !.",
                    "error"
                  );
                  return;
                }
                if (valBusqueda === "") {
                  mensajeAlerta(
                    "Toma de Servicio",
                    "Debe seleccionar un texto de busqueda !.",
                    "error"
                  );
                  return;
                }
                setSelectedRow(null);
                obtenerDatosPacientes({
                  opcion: labelTextBuscar,
                  texto: valBusqueda,
                });
              }
            }}
          />
        </Grid>

        {rows ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ align: "center", margin: "1em" }}
          >
            <div className="datatable-responsive-demo">
              <div className="card">
                <DataTable
                  value={rows}
                  selection={selectedRow}
                  onSelectionChange={(e) => {
                    setSelectedRow(e.value);
                    buscarTitular({ titularId: e.value.id_titular });
                    buscarValorizacion({ titularId: e.value.id_titular });
                    obtenerTope({ titularId: e.value.id_titular });
                    obtenerAdicionales({ titularId: e.value.id_titular });
                    obtenerCantidadServiciosTitular({
                      titularId: e.value.id_titular,
                    });
                    guardarAfiliado({
                      titularId: e.value.id_titular,
                      id: e.value.id,
                      nombre: e.value.nombre_razonsocial,
                      telefono: e.value.telefono1,
                      plan: e.value.planGrupo,
                      dni: e.value.numeroDocumento,
                      domicilio: e.value.domicilio,
                      entreCalles: e.value.entreCalles,
                      grupoId: e.value.id_grupo,
                      planId: e.value.id_plan,
                      coseguroDiurnoPediatrico:
                        e.value.coseguroDiurnoPediatrico,
                      coseguroNocturnoPediatrico:
                        e.value.coseguroNocturnoPediatrico,
                      coseguroDiurnoAdulto: e.value.coseguroDiurnoAdulto,
                      coseguroNocturnoAdulto: e.value.coseguroNocturnoAdulto,
                      provincia: "Buenos Aires",
                    });
                  }}
                  selectionMode="single"
                  dataKey="id"
                  //header={header}
                  className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                  style={{ fontSize: "xx-small" }}
                  globalFilter={globalFilter}
                  emptyMessage="No hay datos."
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                  rows={10}
                  rowsPerPageOptions={[10, 20, 50]}
                  //paginatorLeft={paginatorLeft}
                  //paginatorRight={paginatorRight}
                >
                  <Column
                    field="id_titular"
                    header="Número de Socio"
                    className="colNombre"
                    body={idTemplate}
                    //style={{width: "10%"}}
                    sortable
                  ></Column>
                  <Column
                    field="nombre_razonsocial"
                    header="Nombre/Razón Social"
                    className="colDomicilio"
                    body={nombreTemplate}
                    //style={{width: "20%"}}
                    sortable
                  ></Column>
                  <Column
                    field="domicilio"
                    header="Domicilio"
                    className="colDomicilio"
                    body={domicilioTemplate}
                    //style={{width: "20%"}}
                    sortable
                  ></Column>
                  <Column
                    field="telefono1"
                    header="Teléfono"
                    className="colNombre"
                    body={telefonoTemplate}
                    //style={{width: "10%"}}
                    sortable
                  ></Column>
                  <Column
                    field="numeroDocumento"
                    header="Documento/CUIT"
                    className="colLocalidad"
                    body={documentoTemplate}
                    //style={{width: "10%"}}
                    sortable
                  ></Column>
                  <Column
                    field="planGrupo"
                    header="Plan"
                    className="colDomicilio"
                    body={planGrupoTemplate}
                    //sortable
                  ></Column>
                  <Column
                    field="habilitado"
                    header="Estado"
                    className="colNombre"
                    body={habilitadoTemplate}
                    //style={{width: "10%"}}
                    sortable
                  ></Column>
                </DataTable>
              </div>
            </div>
          </Grid>
        ) : (
          <Spinner />
        )}

        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
