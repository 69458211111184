import React, { Fragment, useContext, useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { Link, Redirect } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Buscar from "./Buscar";
import DatosServicio from "./DatosServicio";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import Grid from "@mui/material/Grid";
import "../../../assets/css/DialogDemo.css";
import { Tag } from "primereact/tag";
import { Dialog } from "primereact/dialog";
import { Button as ButtonPR } from "primereact/button";
import TomaAzul from "./TomaAzul";
import TomaNaranja from "./TomaNaranja";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";

const PREFIX = "TomaServicio";
const classes = {
  root: `${PREFIX}-root`,
  layout: `${PREFIX}-layout`,
  paper: `${PREFIX}-paper`,
  stepper: `${PREFIX}-stepper`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
  dialog: `${PREFIX}-dialog`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: "1vw",
  },
  [`&.${classes.layout}`]: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  [`&.${classes.paper}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(0, 3, 3),
    },
  },

  [`&.${classes.stepper}`]: {
    padding: theme.spacing(0, 0, 2),
  },

  [`&.${classes.buttons}`]: {
    display: "flex",
    justifyContent: "flex-end",
  },
  [`&.${classes.button}`]: {
    marginBottom: theme.spacing(-1),
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(1),
  },
  [`&.${classes.dialog}`]: {
    paddingTop: "0",
    paddingBottom: "0",
    color: "#3F51B5",
  },
}));

const steps = ["Busqueda del Afiliado", "Datos del servicio"];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <Buscar />;
    case 1:
      return <DatosServicio />;
    default:
      throw new Error("Unknown step");
  }
}

export default function TomaServicio(props) {
  //const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [traslado, setTraslado] = useState(false);
  let navigate = useNavigate();

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  let today = new Date();
  let year = today.getFullYear();

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    coseguroAbonar,
    feriados,
    configuracion,
    colores,
    obtenerAcceso,
    guardarCoseguroServicio,
    obtenerFeriados,
    obtenerConfiguracion,
    obtenerAntecedentes,
    obtenerTratamientos,
    obtenerMotivosConsultas,
    obtenerColores,
    guardarAdicionales,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    afiliado,
    titular,
    selectedRow,
    tomaAzul,
    tomaNaranja,
    openModalAzul,
    openModalNaranja,
    numeroIndicativo,
    valorizacion,
    guardarAfiliado,
    agregarNuevoServicio,
    agregarNuevoServicioPendiente,
    setRows,
    guardarValBusqueda,
    setOpenModalAzul,
    setOpenModalNaranja,
    guardarValorizacion,
  } = serviciosContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "TomaServicio" });
    }
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  useEffect(() => {
    guardarAfiliado(null);
    guardarValorizacion(null);
    obtenerFeriados({ year });
    obtenerAntecedentes({ todos: false });
    obtenerTratamientos({ todos: false });
    obtenerMotivosConsultas({ todos: false });
    obtenerConfiguracion();
    obtenerColores();
    setRows(null);
    guardarValBusqueda(null);
    setActiveStep(0);
  }, []);

  function formatDate(dateString) {
    let allDate = dateString.split("-");
    //var thisDate = allDate[0].split("-");
    //var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return allDate;
  }

  const handleNext = () => {
    //console.log(selectedRow)
    if (activeStep === 0 && selectedRow === null) {
      mensajeAlerta(
        "Toma de Servicio",
        "Debe seleccionar un afiliado !.",
        "error"
      );
      return;
    }

    if (
      activeStep === 0 &&
      selectedRow !== null &&
      configuracion.destinatario === 1 &&
      !selectedRow.habilitado
    ) {
      mensajeAlerta(
        "Toma de Servicio",
        "El afiliado esta inhabilitado !.",
        "error"
      );
      return;
    }

    if (
      activeStep === 0 &&
      selectedRow !== null &&
      configuracion.destinatario === 2 &&
      (!selectedRow.habilitado || selectedRow.suspendido)
    ) {
      mensajeAlerta(
        "Toma de Servicio",
        "El afiliado esta inhabilitado o suspendido !.",
        "error"
      );
      return;
    }



    if (activeStep === 1) {
      //Validar

      const obs = document.getElementById('observaciones').value

      let index;

      if (
        !afiliado ||
        afiliado.dni === undefined ||
        afiliado.dni === null ||
        afiliado.dni.trim() === "" ||
        afiliado.dni.length < 2
      ) {
        mensajeAlerta("Toma de Servicio", "El D.N.I. es necesario !.", "error");
        return;
      }

      if (
        !afiliado ||
        afiliado.nombre === undefined ||
        afiliado.nombre === null ||
        afiliado.nombre.trim() === "" ||
        afiliado.nombre.length < 2
      ) {
        mensajeAlerta(
          "Toma de Servicio",
          "El apellido y nombre es necesario !.",
          "error"
        );
        return;
      }

      if (
        !afiliado ||
        afiliado.telefono === undefined ||
        afiliado.telefono === null ||
        afiliado.telefono.trim() === "" ||
        afiliado.telefono.length < 2
      ) {
        mensajeAlerta(
          "Toma de Servicio",
          "El teléfono es necesario !.",
          "error"
        );
        return;
      }

      if (
        !afiliado ||
        afiliado.provincia === undefined ||
        afiliado.provincia === null ||
        afiliado.provincia.trim() === "" ||
        afiliado.provincia.length < 2
      ) {
        mensajeAlerta(
          "Toma de Servicio",
          "La provincia es necesaria !.",
          "error"
        );
        return;
      }

      if (
        !afiliado ||
        afiliado.domicilio === undefined ||
        afiliado.domicilio === null ||
        afiliado.domicilio.trim() === "" ||
        afiliado.domicilio.length < 2
      ) {
        mensajeAlerta(
          "Toma de Servicio",
          "El domicilio es necesario !.",
          "error"
        );
        return;
      }

      if (
        !afiliado ||
        afiliado.localidad === undefined ||
        afiliado.localidad === null ||
        afiliado.localidad.trim() === "" ||
        afiliado.localidad.length < 2
      ) {
        mensajeAlerta(
          "Toma de Servicio",
          "La localidad es necesaria !.",
          "error"
        );
        return;
      }

      if (
        !afiliado ||
        afiliado.edad === undefined ||
        afiliado.edad === null ||
        afiliado.edad.trim() === ""
      ) {
        mensajeAlerta("Toma de Servicio", "La edad es necesaria !.", "error");
        return;
      }

      if (
        !afiliado ||
        afiliado.sexo === undefined ||
        afiliado.sexo === null ||
        afiliado.sexo === ""
      ) {
        mensajeAlerta("Toma de Servicio", "El sexo es necesario !.", "error");
        return;
      }

      if (
        !afiliado ||
        afiliado.antecedenteNombre === undefined ||
        afiliado.antecedenteNombre === null ||
        afiliado.antecedenteNombre.trim() === "" ||
        afiliado.antecedenteNombre.length < 2
      ) {
        mensajeAlerta(
          "Toma de Servicio",
          "El antecedente es necesario !.",
          "error"
        );
        return;
      }

      if (
        !afiliado ||
        afiliado.tratamientoNombre === undefined ||
        afiliado.tratamientoNombre === null ||
        afiliado.tratamientoNombre.trim() === "" ||
        afiliado.tratamientoNombre.length < 2
      ) {
        mensajeAlerta(
          "Toma de Servicio",
          "El tratamiento es necesario !.",
          "error"
        );
        return;
      }

      if (
        !afiliado ||
        afiliado.motivoConsultaNombre === undefined ||
        afiliado.motivoConsultaNombre === null ||
        afiliado.motivoConsultaNombre.trim() === "" ||
        afiliado.motivoConsultaNombre.length < 2
      ) {
        mensajeAlerta(
          "Toma de Servicio",
          "El motivo de consulta es necesario !.",
          "error"
        );
        return;
      }

      if (
        configuracion &&
        configuracion.pami &&
        configuracion.pami === titular.id_titular
      ) {
        if (
          !afiliado ||
          afiliado.indicativoEmisor === undefined ||
          afiliado.indicativoEmisor === null ||
          afiliado.indicativoEmisor.trim() === "" ||
          afiliado.indicativoEmisor.length < 2
        ) {
          mensajeAlerta(
            "Toma de Servicio",
            "El nº de indicativo del servicio de PAMI es necesario !.",
            "error"
          );
          return;
        }

        if (
          !afiliado ||
          afiliado.nroObraSocial === undefined ||
          afiliado.nroObraSocial === null ||
          afiliado.nroObraSocial.trim() === "" ||
          afiliado.nroObraSocial.length < 2
        ) {
          mensajeAlerta(
            "Toma de Servicio",
            "El nº de beneficiario de PAMI es necesario !.",
            "error"
          );
          return;
        }
      }

      if (
        afiliado &&
        afiliado.celular !== undefined &&
        afiliado.celular !== null &&
        afiliado.celular !== "" &&
        afiliado.celular.length !== 10
      ) {
        mensajeAlerta(
          "Toma de Servicio",
          "El WhatsApp debe tener 10 dígitos !.",
          "error"
        );
        return;
      }

      // Valorizacion del servicio

      let valorServicio = 0;

      if (valorizacion !== null) {
        let fueraZona = false;
        let nocturno = false;
        let pediatrico = false;
        let sabDomFer = false;

        if (afiliado.fueraZona) {
          fueraZona = true;
        }

        if (afiliado.edad <= configuracion.edadPediatrico) {
          pediatrico = true;
        }

        var d = new Date(); // for now
        let day = d.getDate();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        var h = today.getHours();
        var m = today.getMinutes();
        var s = today.getSeconds();

        //let datetext = d.getHours()+":"+d.getMinutes()+":"+d.getSeconds();

        if (d.getDay() === 0 || d.getDay() === 6) {
          sabDomFer = true;
        }

        for (var i = 0; i < feriados.length; i++) {
          let f = feriados[i].fecha;
          let df = formatDate(f);
          if (
            Number(day) === Number(df[2]) &&
            Number(month) === Number(df[1]) &&
            Number(year) === Number(df[0])
          ) {
            sabDomFer = true;
          }
        }

        let t1 = h + ":" + m + ":" + s;

        let nd = configuracion.nocturnoDesde;
        let nh = configuracion.nocturnoHasta;

        let str1 = t1.split(":");
        let str2 = nd.split(":");
        let str3 = nh.split(":");

        let totalSeconds1 = parseInt(str1[0] * 3600 + str1[1] * 60 + str1[2]);
        let totalSeconds2 = parseInt(str2[0] * 3600 + str2[1] * 60 + str2[2]);
        let totalSeconds3 = parseInt(str3[0] * 3600 + str3[1] * 60 + str3[2]);

        if (totalSeconds1 > totalSeconds2 || totalSeconds1 < totalSeconds3) {
          nocturno = true;
        }

        //Inicio Anidado

        //VERDE

        if (afiliado.motivoConsultaColor === "VERDE") {
          if (pediatrico && nocturno && sabDomFer && fueraZona) {
            valorServicio = valorizacion.verdePNSdfFz
              ? valorizacion.verdePNSdfFz
              : 0;
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePNSdf
                ? valorizacion.verdePNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePNFz
                ? valorizacion.verdePNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePN ? valorizacion.verdePN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePSdfFz
                ? valorizacion.verdePSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePSdf
                ? valorizacion.verdePSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePFz ? valorizacion.verdePFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeNSdfFz
                ? valorizacion.verdeNSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeNSdf
                ? valorizacion.verdeNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeNFz ? valorizacion.verdeNFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeSdfFz
                ? valorizacion.verdeSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeSdf ? valorizacion.verdeSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (pediatrico && nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePNSdf
                ? valorizacion.verdePNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePN ? valorizacion.verdePN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePSdf
                ? valorizacion.verdePSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeNSdf
                ? valorizacion.verdeNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeSdf ? valorizacion.verdeSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (pediatrico && nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePNFz
                ? valorizacion.verdePNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePN ? valorizacion.verdePN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePFz ? valorizacion.verdePFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeNFz ? valorizacion.verdeNFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (pediatrico && nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePN ? valorizacion.verdePN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (pediatrico && !nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePSdfFz
                ? valorizacion.verdePSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePSdf
                ? valorizacion.verdePSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePFz ? valorizacion.verdePFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeSdfFz
                ? valorizacion.verdeSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeSdf ? valorizacion.verdeSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (pediatrico && !nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePSdf
                ? valorizacion.verdePSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeSdf ? valorizacion.verdeSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (pediatrico && !nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdePFz ? valorizacion.verdePFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (pediatrico && !nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeP ? valorizacion.verdeP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (!pediatrico && nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeNSdfFz
                ? valorizacion.verdeNSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeNSdf
                ? valorizacion.verdeNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeNFz ? valorizacion.verdeNFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeSdfFz
                ? valorizacion.verdeSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeSdf ? valorizacion.verdeSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (!pediatrico && nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeNSdf
                ? valorizacion.verdeNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeSdf ? valorizacion.verdeSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (!pediatrico && nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeNFz ? valorizacion.verdeNFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (!pediatrico && nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeN ? valorizacion.verdeN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (!pediatrico && !nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeSdfFz
                ? valorizacion.verdeSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeSdf ? valorizacion.verdeSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (!pediatrico && !nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeSdf ? valorizacion.verdeSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (!pediatrico && !nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verdeFz ? valorizacion.verdeFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }

          if (!pediatrico && !nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.verde ? valorizacion.verde : 0;
            }
          }
        }

        //AMARILLO

        if (afiliado.motivoConsultaColor === "AMARILLO") {
          if (pediatrico && nocturno && sabDomFer && fueraZona) {
            valorServicio = valorizacion.amarilloPNSdfFz
              ? valorizacion.amarilloPNSdfFz
              : 0;
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPNSdf
                ? valorizacion.amarilloPNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPNFz
                ? valorizacion.amarilloPNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPN
                ? valorizacion.amarilloPN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPSdfFz
                ? valorizacion.amarilloPSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPSdf
                ? valorizacion.amarilloPSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPFz
                ? valorizacion.amarilloPFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloP
                ? valorizacion.amarilloP
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloNSdfFz
                ? valorizacion.amarilloNSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloNSdf
                ? valorizacion.amarilloNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloNFz
                ? valorizacion.amarilloNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloN
                ? valorizacion.amarilloN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloSdfFz
                ? valorizacion.amarilloSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloSdf
                ? valorizacion.amarilloSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloFz
                ? valorizacion.amarilloFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (pediatrico && nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPNSdf
                ? valorizacion.amarilloPNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPN
                ? valorizacion.amarilloPN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPSdf
                ? valorizacion.amarilloPSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloP
                ? valorizacion.amarilloP
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloNSdf
                ? valorizacion.amarilloNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloN
                ? valorizacion.amarilloN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloSdf
                ? valorizacion.amarilloSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (pediatrico && nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPNFz
                ? valorizacion.amarilloPNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPN
                ? valorizacion.amarilloPN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPFz
                ? valorizacion.amarilloPFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloP
                ? valorizacion.amarilloP
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloNFz
                ? valorizacion.amarilloNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloN
                ? valorizacion.amarilloN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloFz
                ? valorizacion.amarilloFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (pediatrico && nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPN
                ? valorizacion.amarilloPN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloP
                ? valorizacion.amarilloP
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloN
                ? valorizacion.amarilloN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (pediatrico && !nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPSdfFz
                ? valorizacion.amarilloPSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPSdf
                ? valorizacion.amarilloPSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPFz
                ? valorizacion.amarilloPFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloP
                ? valorizacion.amarilloP
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloSdfFz
                ? valorizacion.amarilloSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloSdf
                ? valorizacion.amarilloSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloFz
                ? valorizacion.amarilloFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (pediatrico && !nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPSdf
                ? valorizacion.amarilloPSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloP
                ? valorizacion.amarilloP
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloSdf
                ? valorizacion.amarilloSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (pediatrico && !nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloPFz
                ? valorizacion.amarilloPFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloP
                ? valorizacion.amarilloP
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloFz
                ? valorizacion.amarilloFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (pediatrico && !nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloP
                ? valorizacion.amarilloP
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (!pediatrico && nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloNSdfFz
                ? valorizacion.amarilloNSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloNSdf
                ? valorizacion.amarilloNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloNFz
                ? valorizacion.amarilloNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloN
                ? valorizacion.amarilloN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloSdfFz
                ? valorizacion.amarilloSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloSdf
                ? valorizacion.amarilloSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloFz
                ? valorizacion.amarilloFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (!pediatrico && nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloNSdf
                ? valorizacion.amarilloNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloN
                ? valorizacion.amarilloN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloSdf
                ? valorizacion.amarilloSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (!pediatrico && nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloNFz
                ? valorizacion.amarilloNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloN
                ? valorizacion.amarilloN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloFz
                ? valorizacion.amarilloFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (!pediatrico && nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloN
                ? valorizacion.amarilloN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (!pediatrico && !nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloSdfFz
                ? valorizacion.amarilloSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloSdf
                ? valorizacion.amarilloSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloFz
                ? valorizacion.amarilloFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (!pediatrico && !nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloSdf
                ? valorizacion.amarilloSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (!pediatrico && !nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarilloFz
                ? valorizacion.amarilloFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }

          if (!pediatrico && !nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.amarillo ? valorizacion.amarillo : 0;
            }
          }
        }

        //ROJO

        if (afiliado.motivoConsultaColor === "ROJO") {
          if (pediatrico && nocturno && sabDomFer && fueraZona) {
            valorServicio = valorizacion.rojoPNSdfFz
              ? valorizacion.rojoPNSdfFz
              : 0;
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPNSdf
                ? valorizacion.rojoPNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPNFz ? valorizacion.rojoPNFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPN ? valorizacion.rojoPN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPSdfFz
                ? valorizacion.rojoPSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPSdf ? valorizacion.rojoPSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPFz ? valorizacion.rojoPFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoNSdfFz
                ? valorizacion.rojoNSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoNSdf ? valorizacion.rojoNSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoNFz ? valorizacion.rojoNFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoSdfFz
                ? valorizacion.rojoSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (pediatrico && nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPNSdf
                ? valorizacion.rojoPNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPN ? valorizacion.rojoPN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPSdf ? valorizacion.rojoPSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoNSdf ? valorizacion.rojoNSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (pediatrico && nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPNFz ? valorizacion.rojoPNFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPN ? valorizacion.rojoPN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPFz ? valorizacion.rojoPFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoNFz ? valorizacion.rojoNFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (pediatrico && nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPN ? valorizacion.rojoPN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (pediatrico && !nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPSdfFz
                ? valorizacion.rojoPSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPSdf ? valorizacion.rojoPSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPFz ? valorizacion.rojoPFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoSdfFz
                ? valorizacion.rojoSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (pediatrico && !nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPSdf ? valorizacion.rojoPSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (pediatrico && !nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoPFz ? valorizacion.rojoPFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (pediatrico && !nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoP ? valorizacion.rojoP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (!pediatrico && nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoNSdfFz
                ? valorizacion.rojoNSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoNSdf ? valorizacion.rojoNSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoNFz ? valorizacion.rojoNFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoSdfFz
                ? valorizacion.rojoSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (!pediatrico && nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoNSdf ? valorizacion.rojoNSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (!pediatrico && nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoNFz ? valorizacion.rojoNFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (!pediatrico && nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoN ? valorizacion.rojoN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (!pediatrico && !nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoSdfFz
                ? valorizacion.rojoSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (!pediatrico && !nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoSdf ? valorizacion.rojoSdf : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (!pediatrico && !nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojoFz ? valorizacion.rojoFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }

          if (!pediatrico && !nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.rojo ? valorizacion.rojo : 0;
            }
          }
        }

        //FUCCIA

        if (afiliado.motivoConsultaColor === "FUCCIA") {
          if (pediatrico && nocturno && sabDomFer && fueraZona) {
            valorServicio = valorizacion.fucciaPNSdfFz
              ? valorizacion.fucciaPNSdfFz
              : 0;
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPNSdf
                ? valorizacion.fucciaPNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPNFz
                ? valorizacion.fucciaPNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPN ? valorizacion.fucciaPN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPSdfFz
                ? valorizacion.fucciaPSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPSdf
                ? valorizacion.fucciaPSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPFz
                ? valorizacion.fucciaPFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaP ? valorizacion.fucciaP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaNSdfFz
                ? valorizacion.fucciaNSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaNSdf
                ? valorizacion.fucciaNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaNFz
                ? valorizacion.fucciaNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaN ? valorizacion.fucciaN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaSdfFz
                ? valorizacion.fucciaSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaSdf
                ? valorizacion.fucciaSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaFz ? valorizacion.fucciaFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (pediatrico && nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPNSdf
                ? valorizacion.fucciaPNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPN ? valorizacion.fucciaPN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPSdf
                ? valorizacion.fucciaPSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaP ? valorizacion.fucciaP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaNSdf
                ? valorizacion.fucciaNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaN ? valorizacion.fucciaN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaSdf
                ? valorizacion.fucciaSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (pediatrico && nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPNFz
                ? valorizacion.fucciaPNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPN ? valorizacion.fucciaPN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPFz
                ? valorizacion.fucciaPFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaP ? valorizacion.fucciaP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaNFz
                ? valorizacion.fucciaNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaN ? valorizacion.fucciaN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaFz ? valorizacion.fucciaFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (pediatrico && nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPN ? valorizacion.fucciaPN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaP ? valorizacion.fucciaP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaN ? valorizacion.fucciaN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (pediatrico && !nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPSdfFz
                ? valorizacion.fucciaPSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPSdf
                ? valorizacion.fucciaPSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPFz
                ? valorizacion.fucciaPFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaP ? valorizacion.fucciaP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaSdfFz
                ? valorizacion.fucciaSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaSdf
                ? valorizacion.fucciaSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaFz ? valorizacion.fucciaFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (pediatrico && !nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPSdf
                ? valorizacion.fucciaPSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaP ? valorizacion.fucciaP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaSdf
                ? valorizacion.fucciaSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (pediatrico && !nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaPFz
                ? valorizacion.fucciaPFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaP ? valorizacion.fucciaP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaFz ? valorizacion.fucciaFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (pediatrico && !nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaP ? valorizacion.fucciaP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (!pediatrico && nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaNSdfFz
                ? valorizacion.fucciaNSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaNSdf
                ? valorizacion.fucciaNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaNFz
                ? valorizacion.fucciaNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaN ? valorizacion.fucciaN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaSdfFz
                ? valorizacion.fucciaSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaSdf
                ? valorizacion.fucciaSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaFz ? valorizacion.fucciaFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (!pediatrico && nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaNSdf
                ? valorizacion.fucciaNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaN ? valorizacion.fucciaN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaSdf
                ? valorizacion.fucciaSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (!pediatrico && nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaNFz
                ? valorizacion.fucciaNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaN ? valorizacion.fucciaN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaFz ? valorizacion.fucciaFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (!pediatrico && nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaN ? valorizacion.fucciaN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (!pediatrico && !nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaSdfFz
                ? valorizacion.fucciaSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaSdf
                ? valorizacion.fucciaSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaFz ? valorizacion.fucciaFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (!pediatrico && !nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaSdf
                ? valorizacion.fucciaSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (!pediatrico && !nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fucciaFz ? valorizacion.fucciaFz : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }

          if (!pediatrico && !nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.fuccia ? valorizacion.fuccia : 0;
            }
          }
        }

        //CELESTE

        if (afiliado.motivoConsultaColor === "CELESTE") {
          if (pediatrico && nocturno && sabDomFer && fueraZona) {
            valorServicio = valorizacion.celestePNSdfFz
              ? valorizacion.celestePNSdfFz
              : 0;
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePNSdf
                ? valorizacion.celestePNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePNFz
                ? valorizacion.celestePNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePN
                ? valorizacion.celestePN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePSdfFz
                ? valorizacion.celestePSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePSdf
                ? valorizacion.celestePSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePFz
                ? valorizacion.celestePFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteP ? valorizacion.celesteP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteNSdfFz
                ? valorizacion.celesteNSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteNSdf
                ? valorizacion.celesteNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteNFz
                ? valorizacion.celesteNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteN ? valorizacion.celesteN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteSdfFz
                ? valorizacion.celesteSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteSdf
                ? valorizacion.celesteSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteFz
                ? valorizacion.celesteFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (pediatrico && nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePNSdf
                ? valorizacion.celestePNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePN
                ? valorizacion.celestePN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePSdf
                ? valorizacion.celestePSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteP ? valorizacion.celesteP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteNSdf
                ? valorizacion.celesteNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteN ? valorizacion.celesteN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteSdf
                ? valorizacion.celesteSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (pediatrico && nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePNFz
                ? valorizacion.celestePNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePN
                ? valorizacion.celestePN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePFz
                ? valorizacion.celestePFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteP ? valorizacion.celesteP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteNFz
                ? valorizacion.celesteNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteN ? valorizacion.celesteN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteFz
                ? valorizacion.celesteFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (pediatrico && nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePN
                ? valorizacion.celestePN
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteP ? valorizacion.celesteP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteN ? valorizacion.celesteN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (pediatrico && !nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePSdfFz
                ? valorizacion.celestePSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePSdf
                ? valorizacion.celestePSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePFz
                ? valorizacion.celestePFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteP ? valorizacion.celesteP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteSdfFz
                ? valorizacion.celesteSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteSdf
                ? valorizacion.celesteSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteFz
                ? valorizacion.celesteFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (pediatrico && !nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePSdf
                ? valorizacion.celestePSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteP ? valorizacion.celesteP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteSdf
                ? valorizacion.celesteSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (pediatrico && !nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celestePFz
                ? valorizacion.celestePFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteP ? valorizacion.celesteP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteFz
                ? valorizacion.celesteFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (pediatrico && !nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteP ? valorizacion.celesteP : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (!pediatrico && nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteNSdfFz
                ? valorizacion.celesteNSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteNSdf
                ? valorizacion.celesteNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteNFz
                ? valorizacion.celesteNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteN ? valorizacion.celesteN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteSdfFz
                ? valorizacion.celesteSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteSdf
                ? valorizacion.celesteSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteFz
                ? valorizacion.celesteFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (!pediatrico && nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteNSdf
                ? valorizacion.celesteNSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteN ? valorizacion.celesteN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteSdf
                ? valorizacion.celesteSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (!pediatrico && nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteNFz
                ? valorizacion.celesteNFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteN ? valorizacion.celesteN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteFz
                ? valorizacion.celesteFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (!pediatrico && nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteN ? valorizacion.celesteN : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (!pediatrico && !nocturno && sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteSdfFz
                ? valorizacion.celesteSdfFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteSdf
                ? valorizacion.celesteSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteFz
                ? valorizacion.celesteFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (!pediatrico && !nocturno && sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteSdf
                ? valorizacion.celesteSdf
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (!pediatrico && !nocturno && !sabDomFer && fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celesteFz
                ? valorizacion.celesteFz
                : 0;
            }
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }

          if (!pediatrico && !nocturno && !sabDomFer && !fueraZona) {
            if (valorServicio === 0) {
              valorServicio = valorizacion.celeste ? valorizacion.celeste : 0;
            }
          }
        }
      }

      // Fin Anidado

      const trasladoColor = colores.find(
        (color) => color.nombre === afiliado.motivoConsultaColor
      ).traslado;

      if (trasladoColor) {
        setTraslado(true);
      } else {
        setTraslado(false);
      }

      if (trasladoColor) {
        //Valido Azul

        if (
          !tomaAzul ||
          tomaAzul.llegada === undefined ||
          tomaAzul.llegada === null ||
          tomaAzul.llegada.length === 0 ||
          tomaAzul.tipoId === undefined ||
          tomaAzul.tipoId === null ||
          tomaAzul.tipoId === 0 ||
          tomaAzul.provincia === undefined ||
          tomaAzul.provincia === null ||
          tomaAzul.provincia.trim() === "" ||
          tomaAzul.destino === undefined ||
          tomaAzul.destino === null ||
          tomaAzul.destino.trim() === ""
        ) {
          setOpenModalAzul(true);
          return;
        }
      }

      if (afiliado.motivoConsultaColor === "NARANJA") {
        //Valido Naranja

        if (
          !tomaNaranja ||
          tomaNaranja.llegada === undefined ||
          tomaNaranja.llegada === null ||
          tomaNaranja.llegada.length === 0 ||
          !tomaNaranja ||
          tomaNaranja.duracion === undefined ||
          tomaNaranja.duracion === null ||
          tomaNaranja.duracion.trim() === "" ||
          !tomaNaranja ||
          tomaNaranja.cantidadMoviles === undefined ||
          tomaNaranja.cantidadMoviles === null ||
          tomaNaranja.cantidadMoviles <= 0 ||
          !tomaNaranja ||
          tomaNaranja.pedido === undefined ||
          tomaNaranja.pedido === null ||
          tomaNaranja.pedido.trim() === ""
        ) {
          setOpenModalNaranja(true);
          return;
        }
      }

      if (trasladoColor || afiliado.motivoConsultaColor === "NARANJA") {
        agregarNuevoServicioPendiente({
          titular,
          afiliado,
          tomaAzul,
          tomaNaranja,
          usuario: state.usuario.usuario,
          coseguroAbonar,
          obs,
        });
      } else {
        agregarNuevoServicio({
          titular,
          afiliado,
          valorServicio,
          usuario: state.usuario.usuario,
          coseguroAbonar,
          destinatario: configuracion.destinatario,
          autoCarga: configuracion.autoCargaAfiliados,
          obs
        });
      }
    }

    guardarCoseguroServicio(null);
    //guardarAfiliado(null)
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const exitClick = (e) => {
    navigate(`/`);
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const volverServicios = () => {
    setRows(null);
    guardarAfiliado(null);
    guardarValBusqueda(null);
    setActiveStep(0);
  };

  const handleCloseAzul = () => {
    setOpenModalAzul(false);
  };

  const renderHeaderAzul = () => {
    return (
      <div>
        <Tag value="Traslados"></Tag>
      </div>
    );
  };

  const renderFooterAzul = () => {
    return (
      <div>
        <ButtonPR
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => handleAceptarAzul()}
          className="p-button-text"
          autoFocus
        />
        <ButtonPR
          label="Volver"
          icon="pi pi-times"
          onClick={() => handleCloseAzul()}
          className="p-button-text"
        />
      </div>
    );
  };

  const handleAceptarAzul = () => {
    //Validar
    if (
      !tomaAzul ||
      tomaAzul.llegada === undefined ||
      tomaAzul.llegada === null ||
      tomaAzul.llegada.length === 0
    ) {
      mensajeAlerta(
        "Toma de Servicio",
        "La fecha y hora de llegada es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !tomaAzul ||
      tomaAzul.tipoId === undefined ||
      tomaAzul.tipoId === null ||
      tomaAzul.tipoId === 0
    ) {
      mensajeAlerta(
        "Toma de Servicio",
        "El tipo de móvil es necesario !.",
        "error"
      );
      return;
    }

    if (
      !tomaAzul ||
      tomaAzul.provincia === undefined ||
      tomaAzul.provincia === null ||
      tomaAzul.provincia.trim() === ""
    ) {
      mensajeAlerta(
        "Toma de Servicio",
        "La provincia del traslado es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !tomaAzul ||
      tomaAzul.destino === undefined ||
      tomaAzul.destino === null ||
      tomaAzul.destino.trim() === ""
    ) {
      mensajeAlerta(
        "Toma de Servicio",
        "El destino del traslado es necesario !.",
        "error"
      );
      return;
    }

    handleCloseAzul();
  };

  const handleCloseNaranja = () => {
    setOpenModalNaranja(false);
  };

  const renderHeaderNaranja = () => {
    return (
      <div>
        <Tag value="Eventos"></Tag>
      </div>
    );
  };

  const renderFooterNaranja = () => {
    return (
      <div>
        <ButtonPR
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => handleAceptarNaranja()}
          className="p-button-text"
          autoFocus
        />
        <ButtonPR
          label="Volver"
          icon="pi pi-times"
          onClick={() => handleCloseNaranja()}
          className="p-button-text"
        />
      </div>
    );
  };

  const handleAceptarNaranja = () => {
    //Validar
    if (
      !tomaNaranja ||
      tomaNaranja.llegada === undefined ||
      tomaNaranja.llegada === null ||
      tomaNaranja.llegada.length === 0
    ) {
      mensajeAlerta(
        "Toma de Servicio",
        "La fecha y hora de llegada es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !tomaNaranja ||
      tomaNaranja.duracion === undefined ||
      tomaNaranja.duracion === null ||
      tomaNaranja.duracion.trim() === ""
    ) {
      mensajeAlerta("Toma de Servicio", "La duración es necesaria !.", "error");
      return;
    }

    if (
      !tomaNaranja ||
      tomaNaranja.cantidadMoviles === undefined ||
      tomaNaranja.cantidadMoviles === null ||
      tomaNaranja.cantidadMoviles <= 0
    ) {
      mensajeAlerta(
        "Toma de Servicio",
        "La cantidad de móviles es necesaria !.",
        "error"
      );
      return;
    }

    if (
      !tomaNaranja ||
      tomaNaranja.pedido === undefined ||
      tomaNaranja.pedido === null ||
      tomaNaranja.pedido.trim() === ""
    ) {
      mensajeAlerta(
        "Toma de Servicio",
        "Fecha de pedido es necesaria !.",
        "error"
      );
      return;
    }

    handleCloseNaranja();
  };

  //console.log(feriados)

  return (
    <Root className={classes.root}>
      <Barra />
      <Fragment>
        {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
        {statusAcceso === 200 ? (
          <Fragment>
            <CssBaseline />
            <main className={classes.layout}>
              <DialogTitle className="dialogTomaServicio" id="id">
                <Box display="flex" alignItems="center" margin-top="0.1em">
                  <Box flexGrow={1}>
                    <Typography component="h1" variant="h6" align="center">
                      Toma de servicio
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton component={Link} to="/">
                      <CloseIcon style={{ color: "#3F51B5" }} />
                    </IconButton>
                  </Box>
                </Box>
              </DialogTitle>

              <Stepper activeStep={activeStep}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Fragment>
                {activeStep === steps.length ? (
                  <Fragment>
                    <Grid container spacing={2} style={{ padding: "0" }}>
                      {afiliado &&
                      !traslado &&
                      afiliado.motivoConsultaColor !== "NARANJA" ? (
                        <Grid item xs={12} md={4}>
                          <Typography variant="h6">
                            El servicio ha sido cargado con el indicativo{" "}
                            {numeroIndicativo}
                          </Typography>
                        </Grid>
                      ) : null}
                      <Grid item xs={12} md={2} style={{ marginTop: "1vw" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={volverServicios}
                          //className={classes.button}
                        >
                          Volver a tomar servicios
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={2} style={{ marginTop: "1vw" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={exitClick}
                          //className={classes.button}
                        >
                          Volver al menu principal
                        </Button>
                      </Grid>
                    </Grid>
                  </Fragment>
                ) : (
                  <Fragment>
                    {getStepContent(activeStep)}
                    <div style={{ float: "right" }}>
                      {activeStep !== 0 && (
                        <Button
                          onClick={handleBack}
                          //className={classes.button}
                        >
                          Atras
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        //className={classes.button}
                      >
                        {activeStep === steps.length - 1
                          ? "Confirmar"
                          : "Siguiente"}
                      </Button>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            </main>
          </Fragment>
        ) : null}

        <div className="card">
          <Dialog
            header={renderHeaderAzul()}
            visible={openModalAzul}
            footer={renderFooterAzul()}
            breakpoints={{ "960px": "75vw" }}
            style={{
              width: "50vw",
              paddingBottom: "0",
              overflowY: "visible",
            }}
            position="left"
          >
            <TomaAzul />
            <br />
          </Dialog>
        </div>
        <div className="card">
          <Dialog
            header={renderHeaderNaranja()}
            visible={openModalNaranja}
            footer={renderFooterNaranja()}
            breakpoints={{ "960px": "75vw" }}
            style={{
              width: "50vw",
              paddingBottom: "0",
              overflowY: "visible",
            }}
            position="left"
          >
            <TomaNaranja />
            <br />
          </Dialog>
        </div>
      </Fragment>
    </Root>
  );
}
