import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { NumericFormat } from "react-number-format";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Spinner from "../Spinner";
import { Tag } from "primereact/tag";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";
import { RadioButton } from "primereact/radiobutton";

const PREFIX = "MyCard";
const classes = {
  root: `${PREFIX}-root`,
  DarkerDisabledTextField: `${PREFIX}-DarkerDisabledTextField`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },

  [`&.${classes.DarkerDisabledTextField}`]: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.6)", // (default alpha is 0.38)
    },
  },
}));

export default function CierreEmergencias(props) {
  //Context
  const serviciosContext = useContext(ServiciosContext);
  const {
    afiliado,
    servicioSeleccionado,
    cierreEmergencias,
    guardarCierreEmergencias,
  } = serviciosContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    configuracion,
    almacenMovil,
    diagnosticos,
    diagnosticosPami,
    urgenciasPami,
    destinosPami,
    obtenerDiagnosticos,
    obtenerDiagnosticosPami,
  } = configuracionContext;

  //const classes = useStyles({color: afiliado ? afiliado.motivoConsultaColor : null });

  useEffect(() => {
    obtenerDiagnosticos({ todos: false });
  }, []);

  useEffect(() => {
    if (
      configuracion.pami === servicioSeleccionado.titularId &&
      (servicioSeleccionado.color === "VERDE" ||
        servicioSeleccionado.color === "AMARILLO" ||
        servicioSeleccionado.color === "ROJO")
    ) {
      obtenerDiagnosticosPami();

      guardarCierreEmergencias({
        ...cierreEmergencias,
        diagnosticosPami: null,
        urgenciasPami: null,
        destinosPami: null,
      });
    }
  }, []);

  //const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Leer tecla y foco

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "diagnostico":
          campo = document.getElementById("detalleDiagnostico");
          campo.focus();
          break;
        case "detalleDiagnostico":
          campo = document.getElementById("coseguroAbonar");
          campo.focus();
          break;
        case "coseguroAbonar":
          campo = document.getElementById("coseguroAbonado");
          campo.focus();
          break;
        case "google-map":
          campo = document.getElementById("entre");
          campo.focus();
          break;
        case "entre":
          campo = document.getElementById("edad");
          campo.focus();
          break;
        case "edad":
          campo = document.getElementById("sexo");
          campo.focus();
          break;
        case "sexo":
          campo = document.getElementById("antecedente");
          campo.focus();
          break;
        case "antecedente":
          campo = document.getElementById("tratamiento");
          campo.focus();
          break;
        case "tratamiento":
          campo = document.getElementById("motivoConsulta");
          campo.focus();
          break;
        case "motivoConsulta":
          campo = document.getElementById("operadorEmisor");
          campo.focus();
          break;
        case "operadorEmisor":
          campo = document.getElementById("indicativoEmisor");
          campo.focus();
          break;
        case "indicativoEmisor":
          if (
            afiliado.grupoId !== 5 &&
            afiliado.grupoId !== 7 &&
            afiliado.grupoId !== 8
          ) {
            // Convenios-Ferrocarriles-Same
            campo = document.getElementById("covid");
            campo.focus();
            break;
          } else {
            campo = document.getElementById("obraSocial");
            campo.focus();
            break;
          }
        case "obraSocial":
          campo = document.getElementById("covid");
          campo.focus();
          break;

        case "diagnosticoPami":
          campo = document.getElementById("urgenciaPami");
          campo.focus();
          break;
        case "urgenciaPami":
          campo = document.getElementById("destinoPami");
          campo.focus();
          break;
      }
    }

    if (event.keyCode === 27) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "detalleDiagnostico":
          campo = document.getElementById("diagnostico");
          campo.focus();
          break;
        case "coseguroAbonar":
          campo = document.getElementById("detalleDiagnostico");
          campo.focus();
          break;
        case "coseguroAbonado":
          campo = document.getElementById("coseguroAbonar");
          campo.focus();
          break;
        case "entre":
          campo = document.getElementById("google-map");
          campo.focus();
          break;
        case "edad":
          campo = document.getElementById("entre");
          campo.focus();
          break;
        case "sexo":
          campo = document.getElementById("edad");
          campo.focus();
          break;
        case "antecedente":
          campo = document.getElementById("sexo");
          campo.focus();
          break;
        case "tratamiento":
          campo = document.getElementById("antecedente");
          campo.focus();
          break;
        case "motivoConsulta":
          campo = document.getElementById("tratamiento");
          campo.focus();
          break;
        case "operadorEmisor":
          campo = document.getElementById("motivoConsulta");
          campo.focus();
          break;
        case "indicativoEmisor":
          campo = document.getElementById("operadorEmisor");
          campo.focus();
          break;
        case "obraSocial":
          campo = document.getElementById("indicativoEmisor");
          campo.focus();
          break;
        case "covid":
          if (
            afiliado.grupoId !== 5 &&
            afiliado.grupoId !== 7 &&
            afiliado.grupoId !== 8
          ) {
            campo = document.getElementById("indicativoEmisor");
            campo.focus();
            break;
          } else {
            campo = document.getElementById("obraSocial");
            campo.focus();
            break;
          }
        case "urgenciaPami":
          campo = document.getElementById("diagnosticoPami");
          campo.focus();
          break;
        case "destinoPami":
          campo = document.getElementById("urgenciaPami");
          campo.focus();
          break;
      }
    }
  }

  //console.log(servicioSeleccionado)
  //console.log(urgenciasPami)

  return (
    <Root className={classes.root}>
      <Fragment>
        {diagnosticos ? (
          <Grid
            container
            spacing={0}
            style={{ padding: "0", marginTop: "1em" }}
          >
            {servicioSeleccionado.movilId >= 1000 ? (
              <Fragment>
                <Grid item xs={12} md={5}>
                  <TextField
                    id="salidaDerivado"
                    label="Salida"
                    value={
                      cierreEmergencias && cierreEmergencias.salidaDerivado
                        ? cierreEmergencias.salidaDerivado
                        : null
                    }
                    type="datetime-local"
                    fullWidth
                    variant="standard"
                    onKeyDown={handleEnter}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      guardarCierreEmergencias({
                        ...cierreEmergencias,
                        salidaDerivado: e.target.value,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={1}></Grid>

                <Grid item xs={12} md={5}>
                  <TextField
                    id="LlegadaDerivado"
                    label="LLegada"
                    value={
                      cierreEmergencias && cierreEmergencias.llegadaDerivado
                        ? cierreEmergencias.llegadaDerivado
                        : null
                    }
                    type="datetime-local"
                    fullWidth
                    variant="standard"
                    onKeyDown={handleEnter}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      guardarCierreEmergencias({
                        ...cierreEmergencias,
                        llegadaDerivado: e.target.value,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={1}></Grid>
              </Fragment>
            ) : null}

            <Grid item xs={12} md={12}>
              <Autocomplete
                id="diagnostico"
                fullWidth
                disablePortal
                required
                noOptionsText={"No hay opciones"}
                options={diagnosticos}
                getOptionLabel={(option) => option.nombre}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Diagnóstico"
                    variant="standard"
                  />
                )}
                onKeyDown={handleEnter}
                onChange={(event, value) => {
                  if (value) {
                    guardarCierreEmergencias({
                      ...cierreEmergencias,
                      diagnosticoId: value.id,
                      diagnosticoNombre: value.nombre,
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextareaAutosize
                id="detalleDiagnostico"
                maxRows={8}
                minRows={5}
                aria-label="maximum height"
                placeholder="Detalle diagnóstico"
                defaultValue=""
                style={{ width: "-webkit-fill-available", marginTop: ".5em" }}
                onChange={(event) => {
                  guardarCierreEmergencias({
                    ...cierreEmergencias,
                    detalleDiagnostico: event.target.value,
                  });
                }}
              />
            </Grid>

            {configuracion.destinatario !== 4 ||
            (configuracion.destinatario === 4 &&
              servicioSeleccionado.color !== "AZUL") ? (
              <Fragment>
                <Grid item xs={6} md={6}>
                  <TextField
                    id="coseguroAbonar"
                    label="Coseguro a abonar"
                    customInput={TextField}
                    //format="##.##"
                    type="numeric"
                    fullWidth
                    variant="standard"
                    className={classes.DarkerDisabledTextField}
                    size="small"
                    value={parseFloat(
                      servicioSeleccionado.coseguroAbonar
                    ).toFixed(2)}
                    disabled
                    onKeyDown={handleEnter}
                    decimalSeparator="."
                    //prefix="$"
                  />
                </Grid>

                <Grid item xs={6} md={6}>
                  <NumericFormat
                    {...props}
                    id="coseguroAbonado"
                    label="Coseguro abonado"
                    customInput={TextField}
                    //format="#######.##"
                    type="numeric"
                    fullWidth
                    variant="standard"
                    size="small"
                    required
                    onKeyDown={handleEnter}
                    decimalSeparator="."
                    //prefix="$"
                    onChange={(e) => {
                      if (Number(e.target.value) > 0) {
                        guardarCierreEmergencias({
                          ...cierreEmergencias,
                          coseguroAbonado: e.target.value,
                          formaPago: "efectivo",
                        });
                      } else {
                        guardarCierreEmergencias({
                          ...cierreEmergencias,
                          coseguroAbonado: e.target.value,
                          formaPago: null,
                        });
                      }
                    }}
                  />
                </Grid>
              </Fragment>
            ) : (
              <Grid item xs={6} md={6}>
                <NumericFormat
                  {...props}
                  id="tiempoEspera"
                  label="Tiempo de Espera en minutos"
                  customInput={TextField}
                  //format="#######.##"
                  type="numeric"
                  fullWidth
                  variant="standard"
                  size="small"
                  required
                  //onKeyDown={handleEnter}
                  //decimalSeparator="."
                  //prefix="$"
                  onChange={(e) => {
                    if (Number(e.target.value) > 0) {
                      guardarCierreEmergencias({
                        ...cierreEmergencias,
                        tiempoEspera: e.target.value,
                      });
                    } else {
                      guardarCierreEmergencias({
                        ...cierreEmergencias,
                        tiempoEspera: null,
                      });
                    }
                  }}
                />
              </Grid>
            )}

            {configuracion.destinatario === 4 &&
            cierreEmergencias &&
            cierreEmergencias.coseguroAbonado !== undefined &&
            cierreEmergencias.coseguroAbonado &&
            Number(cierreEmergencias.coseguroAbonado) > 0 ? (
              <Fragment>
                <Grid item xs={6} md={6} />

                <Grid item xs={6} md={3}>
                  <label
                    style={{ marginLeft: "0vw", marginTop: "1vw" }}
                    htmlFor="efectivo"
                  >
                    Efectivo
                  </label>
                  <RadioButton
                    inputId="pagoEfectivo"
                    value="efectivo"
                    name="efectivo"
                    onChange={(e) =>
                      guardarCierreEmergencias({
                        ...cierreEmergencias,
                        formaPago: e.target.value,
                      })
                    }
                    checked={
                      cierreEmergencias &&
                      cierreEmergencias.formaPago === "efectivo"
                    }
                    style={{ marginTop: "1.2vw", marginLeft: "0.5vw" }}
                  />
                </Grid>

                <Grid item xs={6} md={3}>
                  <label
                    style={{ marginLeft: "0vw", marginTop: "1vw" }}
                    htmlFor="efectivo"
                  >
                    Transferencia
                  </label>
                  <RadioButton
                    inputId="pagoTransferencia"
                    value="transferencia"
                    name="transferencia"
                    onChange={(e) =>
                      guardarCierreEmergencias({
                        ...cierreEmergencias,
                        formaPago: e.target.value,
                      })
                    }
                    checked={
                      cierreEmergencias &&
                      cierreEmergencias.formaPago === "transferencia"
                    }
                    style={{ marginTop: "1.2vw", marginLeft: "0.5vw" }}
                  />
                </Grid>
              </Fragment>
            ) : null}

            {configuracion.pami === servicioSeleccionado.titularId &&
            (servicioSeleccionado.color === "VERDE" ||
              servicioSeleccionado.color === "AMARILLO" ||
              servicioSeleccionado.color === "ROJO") ? (
              <Grid
                item
                xs={6}
                md={4}
                style={{ paddingLeft: 0, marginTop: "1vw" }}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      color="primary"
                      checked={
                        cierreEmergencias && cierreEmergencias.cierreSoloMito
                          ? cierreEmergencias.cierreSoloMito
                          : false
                      }
                      style={{ marginLeft: "0em" }}
                      onChange={(e) => {
                        guardarCierreEmergencias({
                          ...cierreEmergencias,
                          cierreSoloMito: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Cierre sólo Mito"
                  labelPlacement="start"
                />
              </Grid>
            ) : null}

            <Grid item xs={12} md={12} style={{ marginBottom: "1em" }}></Grid>

            {configuracion.pami === servicioSeleccionado.titularId &&
            cierreEmergencias &&
            (cierreEmergencias.cierreSoloMito === undefined ||
              !cierreEmergencias.cierreSoloMito) &&
            (servicioSeleccionado.color === "VERDE" ||
              servicioSeleccionado.color === "AMARILLO" ||
              servicioSeleccionado.color === "ROJO") ? (
              <Fragment>
                <Grid item xs={12} md={12}>
                  <Tag value="Cierre PAMI"></Tag>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Autocomplete
                    id="diagnosticoPami"
                    fullWidth
                    disablePortal
                    required
                    noOptionsText={"No hay opciones"}
                    options={
                      diagnosticosPami && diagnosticosPami !== null
                        ? diagnosticosPami
                        : []
                    }
                    getOptionLabel={(option) => option.description}
                    renderInput={(params) => (
                      <TextField {...params} label="Diagnóstico" />
                    )}
                    onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        guardarCierreEmergencias({
                          ...cierreEmergencias,
                          diagnosticoPamiId: value.code,
                          diagnosticoPamiNombre: value.description,
                        });
                      } else {
                        guardarCierreEmergencias({
                          ...cierreEmergencias,
                          diagnosticoPamiId: null,
                          diagnosticoPamiNombre: null,
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Autocomplete
                    id="urgenciaPami"
                    fullWidth
                    disablePortal
                    required
                    noOptionsText={"No hay opciones"}
                    options={
                      urgenciasPami && urgenciasPami !== null
                        ? urgenciasPami
                        : []
                    }
                    getOptionLabel={(option) => option.description}
                    renderInput={(params) => (
                      <TextField {...params} label="Urgencias" />
                    )}
                    onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        guardarCierreEmergencias({
                          ...cierreEmergencias,
                          urgenciaPamiId: value.code,
                          urgenciaPamiNombre: value.description,
                        });
                      } else {
                        guardarCierreEmergencias({
                          ...cierreEmergencias,
                          urgenciaPamiId: null,
                          urgenciaPamiNombre: null,
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Autocomplete
                    id="destinoPami"
                    fullWidth
                    disablePortal
                    required
                    noOptionsText={"No hay opciones"}
                    options={
                      destinosPami && destinosPami !== null ? destinosPami : []
                    }
                    getOptionLabel={(option) => option.description}
                    renderInput={(params) => (
                      <TextField {...params} label="Destino Final" />
                    )}
                    onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        guardarCierreEmergencias({
                          ...cierreEmergencias,
                          destinoPamiId: value.code,
                          destinoPamiNombre: value.description,
                        });
                      } else {
                        guardarCierreEmergencias({
                          ...cierreEmergencias,
                          destinoPamiId: null,
                          destinoPamiNombre: null,
                        });
                      }
                    }}
                  />
                </Grid>
              </Fragment>
            ) : null}

            <Grid item xs={12} md={12}></Grid>
          </Grid>
        ) : (
          <Spinner />
        )}
      </Fragment>
    </Root>
  );
}
